.user-checkbox-personal-information {
  position: relative;
  word-break: break-word;

  .calculator__form-checkbox-label {

    &--additional {
      flex-wrap: wrap;
    }
  }

  .calculator__form-checkbox-label-text {
    padding-top: 3px;
    font-size: 14px;
    font-weight: 400;
    text-transform: none;
  }

  .error {
    order: 1;
    width: 100%;
  }

  &__link {
    color: inherit;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: inherit;
      text-decoration: underline;
    }
  }
}

.main-wrap {

  &--thankyou {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;

    @media (max-width: 992px) {
      height: 100vh;
    }

    .main-row {
      align-items: flex-start;

      @media (max-width: 992px) {
        flex-direction: column;
      }

      .col-sm-7 { // stylelint-disable-line

        @media (max-width: 992px) {
          max-width: initial;
        }
      }

      .col-sm-5 { // stylelint-disable-line

        @media (max-width: 992px) {
          max-width: initial;
        }
      }
    }

    .main-block {

      @media (max-width: 992px) {
        margin-left: 0;
      }
    }

    .main-block__desc {
      margin-bottom: 40px;

      p {
        margin-bottom: 43px;
        font-size: 32px;
        line-height: 38px;

        @media (max-width: 992px) {
          font-size: 26px;
          margin-bottom: 6px;
        }

        @media (max-width: 576px) {
          font-size: 20px;
          line-height: 22px;
        }
      }
    }

    .header-btn {
      justify-content: flex-start;

      button {
        min-width: 234px;
        padding-top: 18px;
        padding-bottom: 18px;
        font-size: 24px;
        line-height: 26px;

        @media (max-width: 992px) {
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 16px;
        }

        @media (max-width: 576px) {
          min-width: auto;
          width: 100%;
          padding-top: 10px;
          padding-bottom: 10px;
          font-size: 16px;
          line-height: 18px;
        }
      }

      @media (max-width: 992px) {
        justify-content: center;
      }

      @media (max-width: 576px) {
        display: block;
      }
    }
  }
}

@media (max-width: 576px) {

  .main-wrap {

    &--thankyou {

      &::after {
        display: none;
      }
    }
  }
}
